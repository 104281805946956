import { useEffect, useMemo, useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import { optionalChaining } from 'src/utils'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { useIdentifyUser } from './useIdentifyUser'
import { CurrentAgencyContext } from 'src/components/layout'
import { useContext } from 'react'

import {
  DISPATCH_SUBMISSION_FRAGMENT,
  FORM_FIELD_FRAGMENT,
  FORM_RESPONSE_FRAGMENT,
  FORM_RESPONSE_OVERVIEW_FRAGMENT,
  NOTES_FRAGMENT,
  SUBMISSION_FRAGMENT,
  FILE_FRAGMENT,
  FORM_FIELD_ITEM_FRAGMENT,
  SUPERVISOR_REVIEW_CONFIG_FRAGMENT,
  STATUS_TEMPLATE_FRAGMENT,
  BULLETIN_FRAGMENT,
  BULK_SUBMISSION_FRAGMENT
} from './fragments'
import { orderBy } from 'natural-orderby'
import handleSortOrder, {
  handleSortByCategoryLevel
} from 'src/utils/handleSortOrder'
import { parseUniqActivities } from 'src/utils/dataMapping'

export const GET_NWS_ALERTS = gql`
  query GetNwsAlerts {
    nwsAlerts
  }
`
export const GET_VERSION = gql`
  query GetFrontedVersion {
    frontendVersion
  }
`
export const GET_STATUS = gql`
  query GetUptimeStatus {
    status
  }
`

export const GET_NOTIFCATION = gql`
  query GetPlatformNotification {
    notificationMessage {
      id
      message
      url
      color
    }
  }
`

export const GET_ME = gql`
  query Me {
    me {
      id
      name
      email
      role
      phoneNumber
      stytchMemberId
      displayTags {
        id
        name
        color
      }
      agency {
        id
        name
      }
    }
  }
`

export const GET_CURRENT_AGENCY = gql`
  query CurrentAgency {
    myAgency {
      id
      name
      timezone
      beachRiskOverride
      tappableForms {
        id
        name
        pinnedToSubmitScreen
        fileCategories {
          id
          name
          color
        }
      }
      offlineEligibleFormIds {
        id
      }
      fileCategories(orderBy: { name: asc }) {
        id
        name
        color
      }
      positionCategories(orderBy: { name: asc }) {
        id
        name
        color
      }
      timezone
      settings {
        id
        commsSmsLimit
        logo {
          id
          secureFileUrl
        }
        hasWorkflows
        publicDashboardUrl
        publicDashboardMetricsUrl
        publicDashboardRiskOpts
        hideIncidentSubmitFields
        publicDashboardPaths
        modules {
          key
          value
        }
        dispatchLocationDefault
        dispatchTrackEnRoute
        dispatchHideOutOfService
        nwsOfficegridXgridY
        latitude
        longitude
        noaaTidesStation
        surflineSpotId
        useMeters
        useCelsius
      }
      activities(where: { archived: { equals: false } }) {
        id
        label
        order
        archived
        subLabelOne
        subLabelTwo
        subLabelThree
        quickStat
        color
        notableIncidentDefault
        noteTemplate
        suggestedForms {
          id
          name
        }
        quickStatParents {
          id
        }
        quickStatChildren {
          id
          quickStat
          label
        }
        hideFromQuickSurvey
        pinToBulkIncident
      }
      positions(
        orderBy: { name: asc }
        where: { positionStatus: { not: { equals: "ARCHIVED" } } }
      ) {
        id
        __typename
        name
        dispatchable
        positionStatus
        riskOverride
        riskOverrideReason
        isClosed
        hasEvent
        hideFromPublic
        locationOnly
        hideFromPulse
        secureImageUrl
        order
        lat
        lng
        category {
          id
          name
          color
        }
        tags {
          id
          name
          color
        }
      }
      assets(
        orderBy: { name: asc }
        where: { archived: { equals: false } }
      ) {
        id
        name
        archived
        secureImageUrl
        hideFromPulse
        tags {
          id
          name
          color
        }
        description
      }
      users(
        orderBy: { name: asc }
        where: { rosterStatus: { not: { equals: "ARCHIVED" } } }
      ) {
        id
        name
        rosterStatus
        role
        hideFromPulse
        email
        phoneNumber
        secureImageUrl
        stytchMemberId
        displayTags {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`

export const useGetCurrentAgencyContext = () => {
  const cur = useContext(CurrentAgencyContext)
  return cur
}

export const useGetCurrentAgency = session => {
  const { identify } = useIdentifyUser()
  const {
    data: meData,
    loading: meLoading,
    error: meErrror,
    refetch: getUser
  } = useQuery(GET_ME, {
    skip: !session,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: d => {
      console.log('Identify for analytics...')
      identify(d.me)
    }
  })

  const {
    data: agencyData,
    loading: agencyLoading,
    error: agencyError,
    refetch: agencyRefetch
  } = useQuery(GET_CURRENT_AGENCY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !meData?.me?.agency?.id
  })

  const hasDisplayTags = meData?.me?.displayTags?.length > 0
  const userDisplayTags = meData?.me?.displayTags

  const [allPositionsShowing, setAllPositionsShowing] = useState(
    !hasDisplayTags
  )

  useEffect(() => {
    if (hasDisplayTags) {
      setAllPositionsShowing(false)
    } else {
      setAllPositionsShowing(true)
    }
  }, [hasDisplayTags])

  const currentAgency = optionalChaining(() => agencyData.myAgency)
  const user = optionalChaining(() => meData.me)
  const filteredPos =
    hasDisplayTags && !allPositionsShowing
      ? currentAgency?.positions.filter(i =>
          userDisplayTags.some(t => i?.category?.id === t.id)
        )
      : currentAgency?.positions
  const sortedPositions = useMemo(
    () => handleSortOrder(orderBy(filteredPos || [], 'name')),
    [filteredPos]
  )

  const caReady = user && currentAgency

  const units = caReady && sortedPositions.filter(i => i.dispatchable)
  const error = agencyError || meErrror
  const loading = agencyLoading || meLoading
  const refetch = agencyRefetch || getUser

  return {
    error: error && cleanGraphQLError(error.message),
    loading,
    timezone: currentAgency?.timezone,
    settings: caReady && currentAgency?.settings,
    currentAgency: caReady,

    commsSmsLimit: caReady ? currentAgency.settings.commsSmsLimit : 0,
    users: caReady ? currentAgency.users : [],
    resources: units,
    units: units,
    positionCategories: caReady
      ? currentAgency.positionCategories
      : [],
    locations: caReady
      ? sortedPositions.filter(i => !i.dispatchable)
      : [],
    assets: caReady
      ? orderBy(currentAgency.assets, 'name', 'asc')
      : [],
    bulkCategories: caReady
      ? currentAgency.activities.filter(a => a.pinToBulkIncident)
      : [],
    positions: caReady ? sortedPositions : [],
    allPositions: currentAgency?.positions,
    positionCategories: caReady
      ? currentAgency.positionCategories
      : [],
    categoriesSortedByLevel: caReady
      ? handleSortByCategoryLevel(currentAgency.activities)
      : [],
    categories: caReady
      ? handleSortOrder(currentAgency.activities)
      : [],
    topLevelCategories: caReady
      ? parseUniqActivities(
          handleSortOrder(
            currentAgency.activities.filter(
              a =>
                !a.hideFromQuickSurvey &&
                a.label &&
                !a.label.includes('/')
            )
          ),
          'subLabelOne'
        )
      : [],
    user,
    refetch,
    getCurrentAgency: agencyRefetch,
    getUser,
    setAllPositionsShowing,
    allPositionsShowing,
    loggedIn: user
  }
}

export const GET_TODAYS_DISPATCHER = gql`
  query getTodaysDispatcher {
    todaysDispatcher @client
  }
`

export const useGetTodaysDispatcher = () => {
  const { data, error, loading } = useQuery(GET_TODAYS_DISPATCHER)

  return {
    error: error && cleanGraphQLError(error.message),
    loading,
    id: (data && data.todaysDispatcher) || null
  }
}

export const GET_TODAYS_ASSIGNMENT = gql`
  query getTodaysAssignment {
    todaysResponders @client {
      positionId
      positionName
      responderId
      responderName
      primary
      isLocation
    }
    todaysLocation @client {
      locationId
      locationName
    }
  }
`

export const useGetTodaysAssignment = () => {
  const { data, error, refetch, loading } = useQuery(
    GET_TODAYS_ASSIGNMENT
  )

  return {
    error: error && cleanGraphQLError(error.message),
    loading,
    refetch,
    data
  }
}

export const GET_HOMEBASE_INFO = gql`
  query GetHomeBaseInfo(
    $where: SubmissionWhereInput
    $orderBy: SubmissionOrderByInput
    $skip: Int
    $cursor: SubmissionWhereUniqueInput
    $take: Int
    $includeGroups: Boolean
  ) {
    myAgenciesSubmissions(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
      cursor: $cursor
      includeGroups: $includeGroups
    ) {
      id
      incidentTime
      location {
        id
        prettyAddress
        position {
          id
          name
        }
      }
      type {
        id
        label
        color
      }
      location {
        id
        position {
          id
          name
        }
      }
    }

    myAgenciesSubmissionsWithActiveDispatches {
      id
      incidentTime
      important
      type {
        id
        label
        color
      }
      location {
        id
        lat
        lng
        prettyAddress
        position {
          id
          name
        }
      }

      dispatches(
        where: { archived: { equals: false } }
        orderBy: { createdAt: asc }
      ) {
        id
        createdAt
        onScene
        enRoute
        completedAt
        # isFinalActiveDispatchOnSubmission
        unit {
          id
          name
        }
      }
    }
  }
`

export const GET_SUBMISSIONS = gql`
  query GetSubmissions(
    $where: SubmissionWhereInput
    $orderBy: SubmissionOrderByInput
    $skip: Int
    $cursor: SubmissionWhereUniqueInput
    $take: Int
    $includeGroups: Boolean
    $includeSummaryTitles: Boolean
  ) {
    myAgenciesSubmissions(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
      cursor: $cursor
      includeGroups: $includeGroups
    ) {
      ...SubmissionFragment
      formResponses(orderBy: { createdAt: desc }) {
        ...FormResponseOverviewFragment
      }
    }
  }
  ${SUBMISSION_FRAGMENT}
  ${FORM_RESPONSE_OVERVIEW_FRAGMENT}
`

export const GET_AGENCY_ACTIVITY_FEED = gql`
  query GetEvents(
    $where: SubmissionWhereInput
    $formResponseWhere: FormResponseWhereInput
    $bulletinWhere: BulletinWhereInput
    $orderBy: SubmissionOrderByInput
    $skip: Int
    $cursor: SubmissionWhereUniqueInput
    $formResponseCursor: FormResponseWhereUniqueInput
    $bulletinCursor: BulletinWhereUniqueInput
    $bulkSubmissionCursor: SubmissionWhereUniqueInput
    $take: Int
    $includeTypes: [OperationsFeedEnum]
    $includeSummaryTitles: Boolean
  ) {
    myAgenciesActivityFeed(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
      cursor: $cursor
      bulletinCursor: $bulletinCursor
      formResponseCursor: $formResponseCursor
      formResponseWhere: $formResponseWhere
      bulletinWhere: $bulletinWhere
      includeTypes: $includeTypes
      bulkSubmissionCursor: $bulkSubmissionCursor
    ) {
      feed {
        ... on Submission {
          ...SubmissionFragment
        }
        ... on BulkSubmission {
          id
          createdAt
          incidentTime
          submissionIds
          archived
          bulkNotes: notes {
            id
            text
          }
          breakdown {
            ids
            totalIncidents
            involvedParties
            reporter {
              id
              name
            }
            type {
              id
              label
            }
            location {
              id
              prettyAddress
              lat
              lng
              position {
                id
                name
              }
            }
            resources {
              id
              personnel {
                id
                name
              }
              unit {
                id
                name
              }
            }
          }
        }
        ... on FormResponse {
          ...FormResponseOverviewFragment
          weather {
            id
            airTemp
            waveInterval
            waveHeight
            tideHeight
            waterTemp
            windDirection
            windSpeed
          }
        }
        ... on Bulletin {
          id
          createdAt
          title
          liveUntil
          isActive
          urgency
          author {
            id
            name
          }
        }
      }
      totalCount
    }
  }
  ${SUBMISSION_FRAGMENT}
  ${FORM_RESPONSE_OVERVIEW_FRAGMENT}
`

export const GET_MY_ACTIVITY_FEED = gql`
  query GetMyEvents(
    $where: SubmissionWhereInput
    $formResponseWhere: FormResponseWhereInput
    $orderBy: SubmissionOrderByInput
    $skip: Int
    $cursor: SubmissionWhereUniqueInput
    $formResponseCursor: FormResponseWhereUniqueInput
    $bulkSubmissionCursor: SubmissionWhereUniqueInput
    $take: Int
    $includeSummaryTitles: Boolean
    $includeTypes: [OperationsFeedEnum]
  ) {
    myActivityFeed(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
      cursor: $cursor
      formResponseCursor: $formResponseCursor
      formResponseWhere: $formResponseWhere
      bulkSubmissionCursor: $bulkSubmissionCursor
      includeTypes: $includeTypes
    ) {
      feed {
        ... on Submission {
          ...SubmissionFragment
        }
        ... on BulkSubmission {
          ...BulkSubmissionFragment
        }
        ... on FormResponse {
          ...FormResponseOverviewFragment
          weather {
            id
            airTemp
            waveInterval
            waveHeight
            tideHeight
            waterTemp
            windDirection
            windSpeed
          }
        }
      }
      totalCount
    }
  }
  ${SUBMISSION_FRAGMENT}
  ${BULK_SUBMISSION_FRAGMENT}
  ${FORM_RESPONSE_OVERVIEW_FRAGMENT}
`

export const DISPATCH_POSITIONS = gql`
  query GetDispatchPositions {
    myAgenciesPositions(
      where: {
        positionStatus: { equals: "ONTHEJOB" }
        locationOnly: { equals: false }
      }
    ) {
      id
      dispatchStatus
    }
  }
`

export const DISPATCH_SCREEN_DATA = gql`
  query GetDispatchData {
    myAgenciesSubmissionsWithActiveDispatches {
      ...DispatchSubmissionFragment
    }
  }
  ${DISPATCH_SUBMISSION_FRAGMENT}
`

export const GET_SUBMISSION = gql`
  query GetSubmission($where: SubmissionWhereUniqueInput!) {
    submission(where: $where) {
      id
      createdAt

      involvedPartyInfo {
        id
        isIndividual
        age
        gender
        geoOrigin
      }
      notes(orderBy: { createdAt: desc }) {
        ...NotesFragment
      }
      reporter {
        id
        name
      }
      count
      incidentTime
      medicalRecordCount
      positionCategoryRef
      medicalReportId
      callForService {
        id
        callNumber
      }
      relatedSubmissions {
        id
        count
        involvedPartyInfo {
          id
          isIndividual
          age
          gender
          geoOrigin
        }
        type {
          id
          label
          color
        }
      }
      weather {
        id
        airTemp
        waveInterval
        waveHeight
        tideHeight
        waterTemp
        windDirection
        windSpeed
      }
      vehicleIncidents {
        id
        passengers
        vehicle {
          id
          type
          value
          licenseNumber
        }
      }
      dispatches(where: { archived: { equals: false } }) {
        id
        createdAt
        archived
        wasDispatched
        dispatchedBy {
          id
          name
        }
        unit {
          id
          name
        }
        personnel {
          id
          name
        }
        completedAt
        onScene
        enRoute
      }
      location {
        id
        lat
        lng
        prettyAddress
        position {
          id
          name
        }
      }
      type {
        id
        label
        subLabelOne
        subLabelTwo
        subLabelThree
        color
      }
    }
  }
  ${NOTES_FRAGMENT}
`

export const useGetSubmission = (id, options) => {
  const { data, error, loading, refetch } = useQuery(GET_SUBMISSION, {
    variables: {
      where: {
        id
      }
    },
    ...options
  })

  return {
    error: error && cleanGraphQLError(error.message),
    loading,
    refetch,
    submission: data && data.submission
  }
}

export const GET_MY_AGENCY_DOCUMENTS = gql`
  query GetAgencyFilesPList(
    $where: FileWhereInput
    $cursor: FileWhereUniqueInput
    $take: Int
    $skip: Int
    $orderBy: [FileOrderByInput!]
  ) {
    myAgenciesFiles(
      take: $take
      where: $where
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
    ) {
      ...FileFragment
    }
  }
  ${FILE_FRAGMENT}
`

export const GET_MY_AGENCIES_FORMS = gql`
  query GetAgencyForms(
    $where: FormWhereInput
    $cursor: FormWhereUniqueInput
    $take: Int
    $skip: Int
    $orderBy: [FormOrderByInput!]
  ) {
    myAgenciesForms(
      take: $take
      where: $where
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
    ) {
      id
      name
      createdAt
      updatedAt
      responseCount
      archived
      pinned
      pinnedToSubmitScreen
      hasPulseConnections
      hasWorkflowConnection
      createdBy {
        id
        name
      }
      supervisorReviewConfig {
        id
      }
      fileCategories {
        id
        name
        color
      }
    }
  }
`

export const GET_FORM_QR_CODE = gql`
  query GetAgencyFormsQR($id: String!) {
    form(where: { id: $id }) {
      id
      name
      qrCode
    }
  }
`

export const GET_BULLETIN = gql`
  query GetBulletin($id: String!) {
    bulletin(where: { id: $id }) {
      id
      title
      body
      createdAt
      urgency
      liveUntil
      isPrivate
      isActive
      readByCount
      hasRead
      whiteList {
        id
        name
      }
      whiteListedGroups {
        id
        name
        color
      }
      author {
        id
        name
      }
      attachments {
        ...FileFragment
      }
    }
  }
  ${FILE_FRAGMENT}
`
export const GET_BULLETIN_READ_BY = gql`
  query GetBulletinPermittedUsers($id: String!) {
    bulletin(where: { id: $id }) {
      id
      author {
        id
      }
      readBy {
        id
        name
      }
      permittedUsers {
        id
        name
      }
    }
  }
`

export const GET_MY_AGENCIES_FORM_RESPONSES = gql`
  query GetAgencyFormResponses(
    $where: FormResponseWhereInput
    $cursor: FormResponseWhereUniqueInput
    $take: Int
    $skip: Int
    $includeSummaryTitles: Boolean
  ) {
    myAgenciesFormResponses(
      take: $take
      where: $where
      cursor: $cursor
      orderBy: { createdAt: desc }
      skip: $skip
    ) {
      ...FormResponseOverviewFragment
    }
  }
  ${FORM_RESPONSE_OVERVIEW_FRAGMENT}
`

export const GET_FORM = gql`
  query GetForm(
    $where: FormWhereUniqueInput!
    $fieldsWhere: FormFieldWhereInput
    $fieldItemsWhere: FormFieldItemWhereInput
  ) {
    form(where: $where) {
      id
      name
      createdAt
      updatedAt
      autoSave
      autoWeather
      formTemplate {
        id
        secureImageUrl
      }
      createdBy {
        id
        name
      }
      fileCategories {
        id
        name
        color
      }
      formAlert {
        id
        contactInfos
      }
      activityTypes(where: { archived: { equals: false } }) {
        id
        label
      }
      supervisorReviewConfig {
        ...SupervisorReviewConfigFragment
      }
      medicalFormConfig {
        id
      }
      fields(where: $fieldsWhere) {
        ...FormFieldFragment
        fieldItems(where: $fieldItemsWhere) {
          ...FormFieldItemFragment
          autoUpdates {
            id
            createdAt
            active
            relationFromFormQuestion {
              id
              helpText
              archived
              type
              formField {
                id
                title
                archived
              }
            }
            updatesStatus {
              id
              title
              archived
            }
          }
        }
      }
    }
  }
  ${FORM_FIELD_FRAGMENT}
  ${FORM_FIELD_ITEM_FRAGMENT}
  ${SUPERVISOR_REVIEW_CONFIG_FRAGMENT}
`

export const GET_FORM_RESPONSE = gql`
  query GetFormResponse($where: FormResponseWhereUniqueInput!) {
    formResponse(where: $where) {
      ...FormResponseFragment
    }
  }
  ${FORM_RESPONSE_FRAGMENT}
`

export const GET_SECURE_FILE = gql`
  query GetSecureFile($fileId: ID!, $shareable: Boolean) {
    getSecureFile(fileId: $fileId, shareable: $shareable)
  }
`
export const GET_MY_AGENCY_REPORTS = gql`
  query GetMyAgencyReports(
    $where: ReportWhereInput
    $cursor: ReportWhereUniqueInput
    $take: Int
    $skip: Int
    $orderBy: [ReportOrderByInput!]
  ) {
    myAgenciesReports(
      take: $take
      where: $where
      cursor: $cursor
      orderBy: $orderBy
      skip: $skip
    ) {
      id
      options
      name
      frequency
      configError
      emails
      cron
      archived
      active
    }
  }
`

export const GET_SUBMISSION_ACTIVITY = gql`
  query MyAgenciesSubmissionActivity(
    $date: DateTime!
    $type: EntitySubmissionActivityType!
  ) {
    myAgenciesSubmissionActivity(date: $date, type: $type) {
      id
      name
      lastIncidentSubmission
      todaysIncidentCount
      lastFormSubmission
      todaysFormCount
    }
  }
`

export const GET_SUBMISSION_TRIAGE = gql`
  query MyAgenciesSubmissionTriage {
    myAgenciesSubmissionTriage {
      submissionsWithoutLocations
      submissionsWithoutCategory
      draftForms
      dispatchesWithoutResource
      dispatchesWithoutPersonnel
    }
  }
`

export const GET_USER_STATUSES = gql`
  query UserStatuses($userId: String!) {
    myAgency {
      id
      users(where: { id: { equals: $userId } }) {
        id
        statuses {
          statusName
          reportedBy {
            name
          }
          values
          submittedAt
        }
      }
    }
  }
`

export const GET_POSITION_STATUSES = gql`
  query PositionStatuses($positionId: String!) {
    me {
      id
      agency {
        id
        positions(where: { id: { equals: $positionId } }) {
          id
          statuses {
            statusName
            reportedBy {
              name
            }
            values
            submittedAt
          }
        }
      }
    }
  }
`

export const GET_STATUS_VALUE_HISTORY = gql`
  query StatusValueHistory(
    $statusTemplateId: ID!
    $where: StatusValueWhereInput
    $take: Int
    $skip: Int
    $cursor: StatusValueWhereUniqueInput
  ) {
    getStatusValueHistory(
      statusTemplateId: $statusTemplateId
      where: $where
      take: $take
      skip: $skip
      cursor: $cursor
    ) {
      id
      value
      scalarValue
      createdAt
      completedAt
      notes
      prettyValue
      alertLevel
      color
      isMostCurrentStatusForNode
      files {
        id
      }

      submittedBy {
        id
        name
      }
    }
  }
`

export const GET_AGENCY_CURRENT_STATUS_VALUES = gql`
  query MyAgenciesCurrentStatuses(
    $statusOnNodeType: StatusTemplateNodeTypeEnum!
  ) {
    myAgenciesCurrentStatuses(statusOnNodeType: $statusOnNodeType) {
      id
      title
      isPrimary
      type
      statusOnNodeType
      statusValues {
        id
        value
        prettyValue
        relationId
        hasFiles
        relationType
        createdAt
        completedAt
        color
        alertLevel
        expirationStatus
        expirationColor
      }
    }
  }
`

export const GET_USERS_SECURITY_INFO = gql`
  query GetUsersSignedUp($userId: ID!) {
    myAgenciesSecurityInfo(userId: $userId) {
      id
      passwordLoginEnabled
      oauthProviders
      sessions
      allowedMethods {
        type
      }
      ssoEnabled
      isSignedUp
      needsEmailVerified
      mfaEnabled
    }
  }
`

export const GET_MY_SECURITY_INFO = gql`
  query GetMySecurityInfo {
    myAgenciesSecurityInfo {
      id
      mfaEnabled
      mfaRequired
      allowedMethods {
        type
        connectionId
      }
      passwordLoginEnabled
      oauthProviders
      sessions
    }
  }
`

export const GET_DISPATCH_AUTOMATIONS = gql`
  query GetMyAgenciesDispatchAutomations(
    $where: DispatchAutomationWhereInput
  ) {
    myAgenciesDispatchAutomations(
      where: $where
      orderBy: { createdAt: desc }
    ) {
      id
      incidentCategory {
        id
        label
        color
      }
      hour
      minute
      active
      frequency
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`

export const GET_MY_AGENCIES_FORM_OPTIONS = gql`
  query GetAgencyFormOptions(
    $cursor: FormWhereUniqueInput
    $take: Int
    $skip: Int
  ) {
    myAgenciesForms(
      take: $take
      where: { archived: { equals: false } }
      cursor: $cursor
      orderBy: { createdAt: desc }
      skip: $skip
    ) {
      id
      name
    }
  }
`

export const GET_MY_AGENCIES_REVIEW_STATUS_OPTIONS = gql`
  query GetMyAgenciesReviewStatusOptions {
    myAgenciesReviewStatusOptions {
      reviewStatus
      reviewStatusColor
      notActive
      _count
    }
  }
`

const GET_WEATHER = gql`
  query GetWeatherData($weatherInput: [WeatherInput!]!) {
    getWeatherData(weatherInput: $weatherInput)
  }
`

export const useGetWeather = (parameterData, settings) => {
  const { data, loading } = useQuery(GET_WEATHER, {
    variables: {
      weatherInput: {
        ...parameterData,
        latitude: parseFloat(parameterData.latitude),
        longitude: parseFloat(parameterData.longitude)
      }
    },
    ...settings
  })
  return {
    data: data?.getWeatherData?.[0],
    loading
  }
}

export const UNFINISHED_FORM_ALERTS = gql`
  query UnfinishedFormAlerts {
    myUnfinishedFormCount
  }
`

const GET_LATEST_SUPERVISOR_REVIEW = gql`
  query GetLatestSupervisorReview($subId: String!) {
    submission(where: { id: $subId }) {
      id
      supervisorReviews(take: 20) {
        id
      }
    }
  }
`

export const useGetLatestSupervisorReviews = (
  subId,
  options = {}
) => {
  const { data, loading, ...rest } = useQuery(
    GET_LATEST_SUPERVISOR_REVIEW,
    {
      ...options,
      variables: {
        subId
      }
    }
  )
  return {
    ...rest,
    data: data?.submission?.supervisorReviews,
    loading
  }
}

export const GET_STATUS_TEMPLATES_CATEGORIES = gql`
  query StatusTemplatesCategories {
    myAgenciesStatusTemplateCategories {
      id
      name
      color
    }
  }
`

export const GET_STATUS_TEMPLATES = gql`
  query StatusTemplates($where: StatusTemplateWhereInput) {
    myAgenciesStatusTemplates(where: $where) {
      ...StatusTemplateFragment
      updatedBy(
        where: {
          active: { equals: true }
          updatesStatus: { archived: { equals: false } }
          valueFromFormQuestion: { archived: { equals: false } }
          relationFromFormQuestion: { archived: { equals: false } }
        }
      ) {
        valueFromFormQuestion {
          id
          helpText
          formField {
            id
            title
            form {
              id
              name
            }
          }
        }
      }
    }
  }
  ${STATUS_TEMPLATE_FRAGMENT}
`

export const GET_USER_IMAGES = gql`
  query GetUserImages($ids: [String!]) {
    myAgency {
      id
      users(where: { id: { in: $ids } }) {
        id
        secureImageUrl
      }
    }
  }
`

export const GET_LOGIN_METHODS_BY_EMAIL = gql`
  query GetAllowedLogins($email: String!) {
    getAllowedLogins(email: $email) {
      type
      connectionId
    }
  }
`

export const GET_MY_AGENCY_STATUS_VALUE_REPORTS = gql`
  query GetMyAgencyStatusValueReports(
    $where: StatusValueReportWhereInput
    $orderBy: [StatusValueReportOrderByInput!]
  ) {
    myAgenciesStatusValueReports(where: $where, orderBy: $orderBy) {
      id
      name
      createdAt
      options
      archived
    }
  }
`

export const GET_HOMEBASE_TASKS = gql`
  query GetHomebaseTasks($assignments: [AssignmentWhereInput!]!) {
    myWorkflow(assignments: $assignments) {
      workflows {
        id
        steps {
          id
          type
          title
          displayTags {
            id
            name
            color
          }
          timestamp
          workflowName
          responses {
            ... on FormResponse {
              id
              createdAt
              __typename
              draft
              summary
              stepId
              submittedBy {
                id
                name
              }
              form {
                id
              }
            }
            ... on BulkSubmission {
              ...BulkSubmissionFragment
              stepId
            }
          }
          forms {
            name
            id
          }
          bulkSubmit
        }
      }

      files {
        ...FileFragment
      }
      bulletins {
        ...BulletinFragment
      }
    }
  }
  ${BULLETIN_FRAGMENT}
  ${FILE_FRAGMENT}
  ${BULK_SUBMISSION_FRAGMENT}
`

export const GET_WORKFLOWS = gql`
  query GetWorkflows {
    myAgenciesWorkflows {
      id
      name
      archived
      displayTags {
        id
        name
        color
      }
      steps {
        id
        type
        hour
        minute
        title
        forms {
          id
          name
        }
        bulkSubmit
      }
    }
  }
`
